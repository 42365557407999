import Error from '@components/common/Error'

const ServerError = ({ statusCode, title, hasGetInitialPropsRun, err }) => {
  return <Error statusCode={statusCode} title={title} />
}

ServerError.getInitialProps = async (context) => {
  const { res, err, asPath } = context

  const statusCode = res ? res.statusCode : err?.statusCode ?? 500
  const title = res ? res.statusMessage : err?.message ?? 'An error occurred'

  const errorInitialProps = { statusCode, title }

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true

  return errorInitialProps
}

export default ServerError
